/**=====================
     Pagination scss
==========================**/
.custome-pagination {
  margin-top: calc(22px + (35 - 22) * ((100vw - 320px) / (1920 - 320)));

  .pagination {
    flex-wrap: wrap;
    gap: calc(6px + (13 - 6) * ((100vw - 320px) / (1920 - 320)));
    align-items: center;

    .page-item {
      border-radius: 5px;
      overflow: hidden;

      &:not(:first-child) {
        .page-link {
          margin: 0;
        }
      }

      &.active {
        .page-link {
          background-color: var(--theme-color);
          border-color: var(--theme-color);
          color: $white;
          z-index: unset;

          &:focus {
            background-color: var(--theme-color);
            color: $white;
            border-color: var(--theme-color);
          }
        }
      }

      &:first-child,
      &:last-child {
        &.disabled {
          opacity: 0.3;
        }
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.3;
      }

      .page-link {
        color: $content-color;
        border: 1px solid transparent;
        max-width: 36px;
        height: 32px;
        @include flex_common;

        i {
          font-size: 22px;

          body.rtl & {
            transform: rotateY(180deg);
          }
        }

        &:hover {
          border-radius: 5px;
          border: 1px solid var(--theme-color);
          background-color: unset;
        }

        &:focus {
          color: var(--theme-color);
          background-color: $white;
          box-shadow: none;
        }

      }
    }
  }
}
