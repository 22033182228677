/**=====================
     Inner scss
==========================**/
.table {
  &:not(:last-child) {
    &:last-child {
      * {
        border-bottom: none;
      }
    }
  } 
}

.inner-title {
  margin-bottom: calc(32px + (55 - 32) * ((100vw - 320px) / (1920 - 320)));
  text-align: center;

  h2 {
    font-size: calc(21px + (35 - 21) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    color: $title-color;
    padding-bottom: 20px;
    display: inline-block;
    text-transform: none;
    position: relative;

    &::after {
      @include pos;
      @include center(horizontal);
      @include pseudowh($width: 70px, $height: 1px);
      bottom: 0;
      background-color: var(--theme-color);
    }

    img {
      @include center(horizontal);
      bottom: -11px;
      position: absolute;
      background-color: $white;
      z-index: 1;
      padding: 0 10px;
    }
  }

  &-2 {
    margin-bottom: calc(18px + (38 - 18) * ((100vw - 320px) / (1920 - 320)));

    h3 {
      font-size: calc(24px + (30 - 24) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 600;
    }
  }
}

/**=====================
     404 Scss
==========================**/
.section-404 {
  .image-404 {
    text-align: center;

    img {
      width: 25%;

      @include mq-max(3xl) {
        width: 40%;
      }

      @include mq-max(lg) {
        width: 50%;
      }

      @include mq-max(sm) {
        width: 70%;
      }
    }
  }

  .contain-404 {
    width: 50%;
    text-align: center;
    margin: calc(20px + (45 - 20) * ((100vw - 320px) / (1920 - 320))) auto 0;
    font-family: $public-sans;

    @include mq-max(4xl) {
      width: 60%;
    }

    @include mq-max(xl) {
      width: 80%;
    }

    @include mq-max(md) {
      width: 100%;
    }

    h3 {
      font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.5;
      font-weight: 400;
    }

    button {
      margin-top: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
  }
}

/**=====================
     Cart Scss
==========================**/
.cart-section {
  .deliver-box {
    padding: 22px 18px;
    background-color: $light-gray;
    border-radius: 6px;
    position: relative;
    margin-bottom: calc(22px + (40 - 22) * ((100vw - 320px) / (1920 - 320)));

    .btn-close {
      @include center(vertical);
      position: absolute;
      right: 18px;
    }

    h5 {
      &:first-child {
        margin-bottom: 8px;
      }

      &:last-child {
        font-weight: 500;
        line-height: 1.4;

        a {
          text-decoration: underline;
          margin-left: 5px;
        }
      }
    }
  }

  .cart-title {
    margin-bottom: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
  }

  .promo-code-box {
    margin-bottom: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));

    .form-label {
      font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 500;
    }
  }

  .total-box {
    @include flex_common($dis: flex, $align: center, $justify: space-between);
    padding: 22px 28px;
    border: 1px solid $border-color;
  }

  .member-button {
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    letter-spacing: 1.4px;
  }

  .special-seller-box {
    padding: 20px;
    background-color: $light-gray;
    border-radius: 6px;
    border-radius: 5px;
    border: 1px dashed #bfbfbf;

    h5 {
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0;
      padding-bottom: 18px;
      border-bottom: 1px solid $content-color;
      margin-bottom: 25px;
    }

    .sub-total-table {
      tbody {
        tr {
          td {
            &:last-child {
              font-weight: 500;
            }
          }

          &.sub-total {
            td {
              font-size: 16px;
              font-weight: 400;
              padding-bottom: 26px;

              &:last-child {
                text-align: right;
              }
            }
          }

          &.shipping-title {
            td {
              border: none;
              padding-top: 20px;
              padding-bottom: 10px;
            }
          }

          &.shipping-list {
            td {
              border: none;

              .form-check {
                margin-bottom: 0;

                .form-check-input {
                  @include pseudowh($width: 18px, $height: 18px);
                  margin-top: 2px;
                  margin-right: 10px;

                  &:checked {
                    background-color: var(--theme-color);
                    border-color: var(--theme-color);
                  }

                  &:focus {
                    box-shadow: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .cart-total-box {
    padding: 20px;
    background-color: $light-gray;
    border-radius: 6px;
    text-align: center;

    h3 {
      font-weight: bold;
      color: $title-color;
      margin-bottom: 15px;
    }

    h6 {
      width: 100%;
      margin: 20px 0 10px;
      color: $title-color;
      font-weight: 500;
      font-size: 16px;
    }

    p {
      margin: 0;
      font-size: 14px;
      letter-spacing: 0.5px;
      line-height: 1.4;
    }

    .checkout-button {
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      margin-top: 12px;
    }
  }
}

/**=====================
     Checkout Scss
==========================**/
// Shopping Cart Box
.cart-table {
  background-color: $light-gray;
  padding: calc(18px + (35 - 18) * ((100vw - 320px) / (1920 - 320))) calc(12px + (25 - 12) * ((100vw - 320px) / (1920 - 320)));

  &.cart-table-2 {
    background-color: transparent;
    padding: 0;

    table {
      margin-bottom: 0;

      thead {
        tr {
          th {
            font-size: 20px;
            font-weight: 500;
            color: $content-color;
            padding: 0 22px 16px;
          }
        }
      }

      tbody {
        border-top: 1px solid $border-color;

        tr {
          td {
            vertical-align: middle;
          }

          &:first-child {
            td {
              padding: 22px 16px;
            }
          }
        }
      }
    }
  }

  table {
    margin-bottom: 0;

    tbody {
      tr {
        &:last-child {
          td {
            border: none;
            padding-bottom: 0;
          }
        }

        &:first-child {
          td {
            padding-top: 0;
          }
        }

        td {
          padding: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320))) 16px;
          min-width: calc(135px + (170 - 135) * ((100vw - 320px) / (1920 - 320)));

          .table-title {
            margin-bottom: 12px;
            font-size: 14px;
            font-weight: 500;
          }

          &.product-detail {
            max-width: 260px;

            &:hover {
              .product {
                .product-image {
                  img {
                    transform: scale(1.1);
                  }
                }
              }
            }

            ul {
              li {
                +li {
                  margin-bottom: 2px;
                }
              }
            }

            .product {
              display: flex;
              align-items: center;
              overflow: hidden;

              @include mq-max(2xl) {
                align-items: flex-start;
              }

              .product-image {
                width: 70px;

                @include mq-max(2xl) {
                  width: 80px;
                }

                @include mq-max(sm) {
                  width: 90px;
                }

                @include mq-max(xs) {
                  width: 100px;
                }

                @include mq-max(2xs) {
                  width: 70px;
                }

                img {
                  transition: all 0.3s ease-in-out;
                }
              }

              .product-detail {
                margin-left: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
                margin-top: 0;
                width: calc(100% - 95px);

                body.rtl & {
                  margin-left: unset;
                  margin-right: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
                }

                ul {
                  body.rtl & {
                    padding-right: 0;
                  }

                  li {
                    display: block;
                    white-space: nowrap;
                    margin-bottom: 8px;
                    font-size: 14px;

                    +li {
                      margin-bottom: calc(0px + (2 - 0) * ((100vw - 320px) / (1920 - 320)));
                    }

                    .saving {
                      font-weight: 500;
                    }

                    span {
                      font-weight: 500;
                    }

                    &.name {
                      a {
                        font-weight: 500;
                        font-size: 16px;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                        color: $title-color;

                        &:hover {
                          color: $title-color;
                        }
                      }
                    }

                    &:nth-child(n + 4) {
                      display: none;
                    }

                    .price {
                      display: flex;
                      align-items: center;

                      h6 {
                        margin-left: 8px;
                        text-decoration: line-through;
                      }
                    }

                    &.quantity-price-box {
                      border: 1px solid $border-color;
                      border-radius: 5px;
                      width: 62%;

                      @include mq-max(sm) {
                        width: 80%;
                      }

                      @include mq-max(2xs) {
                        width: 90%;
                      }

                      .cart_qty {
                        button {
                          padding: calc(3px + (10 - 3) * ((100vw - 320px) / (1920 - 320))) calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320)));
                          border: none;

                          input {
                            padding: 10px 18px;
                            border: none;
                            text-align: center;
                          }
                        }
                      }
                    }

                    &.quantity {
                      margin: 6px 0;

                      .quantity-price {
                        width: 50%;

                        @include mq-max(xs) {
                          width: 60%;
                        }

                        .cart_qty {
                          button {
                            @include pseudowh($width: calc(26px + (35 - 26) * ((100vw - 320px) / (1920 - 320))),
                              $height: calc(26px + (35 - 26) * ((100vw - 320px) / (1920 - 320))));
                            @include flex_common;
                            border: none;
                            background-color: #fffefe;
                            border-radius: 100% !important;
                            padding: 0;

                            i {
                              margin-top: 2px;
                            }
                          }

                          input {
                            padding: 0;
                            border: none;
                            text-align: center;
                            background-color: transparent;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &.price {
            h5 {
              font-weight: 500;
              font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
              display: flex;
              align-items: center;

              del {
                font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320)));
                margin-left: 4px;

                body.rtl & {
                  margin-left: unset;
                  margin-right: 4px;
                }
              }
            }

            h6 {
              margin-top: 5px;
              white-space: nowrap;
              font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
              font-weight: 500;
            }
          }

          &.saving {
            font-size: 16px;
            font-weight: 500;
            color: var(--theme-color);
          }

          &.quantity {
            width: 20%;
          }

          .quantity-price {
            width: 117px;

            .cart_qty {
              button {
                @include pseudowh($width: calc(29px + (35 - 29) * ((100vw - 320px) / (1920 - 320))),
                  $height: calc(29px + (35 - 29) * ((100vw - 320px) / (1920 - 320))));
                @include flex_common;
                border: none;
                background-color: #ffffff;
                border-radius: 100% !important;
                padding: 0;
                z-index: 0;

                i {
                  font-size: 16px;
                }
              }

              input {
                padding: 0;
                border: none;
                text-align: center;
                background-color: transparent;
              }
            }
          }

          &.subtotal {
            h5 {
              font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
              font-weight: 500;
              display: inline-block;
            }

            .btn-close {
              margin-left: 50px;
            }
          }

          &.save-remove {
            .save {
              font-weight: 400;
              white-space: nowrap;
              font-size: 16px;
              display: block;
              text-decoration: underline;

              &:hover {
                color: var(--theme-color);
              }
            }

            .remove {
              font-weight: 400;
              font-size: 15px;
              color: #bf2020;
              text-decoration: underline;
            }
          }
        }
      }
    }

    tfoot {
      border-top: 1px solid $border-color;

      tr {
        td {
          &:first-child {
            border: none;
          }

          &:nth-child(2) {
            font-size: 16px;
            font-weight: 500;
            padding: 16px;
            border-left: 1px solid $border-color;
          }

          &:last-child {
            font-size: 16px;
            font-weight: 500;
            border-right: 1px solid $border-color;
          }
        }
      }
    }
  }
}

// Summery cart box
.summery-box {
  border-radius: 5px;
  background-color: $light-gray;

  .summery-header {
    padding: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320))) calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border-color;

    h3 {
      font-weight: 600;
    }

    a {
      font-weight: 500;
      margin-left: auto;
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

      &:hover {
        color: var(--theme-color);
      }

      body.rtl & {
        margin-left: unset;
        margin-right: auto;
      }
    }
  }

  .summery-contain {
    padding: 16px calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    border-bottom: 1px solid $border-color;

    &::-webkit-scrollbar-track {
      background-color: $content-color;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--theme-color);
      border-radius: 4px;
    }

    .coupon-cart {
      .coupon-box {
        position: relative;
        overflow: hidden;
        border-radius: 4px;

        .btn-apply {
          background: var(--theme-color);
          color: $white;
          padding: 0 calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 700;
          border: none;
        }

        .form-control {
          border: 2px solid var(--theme-color);
          padding: 10px;
        }
      }
    }

    ul {
      body.rtl & {
        padding-right: 0;
      }

      li {
        padding: 10px 0;
        display: flex;
        align-items: center;

        &:last-child {
          border-bottom: none;
        }

        h4 {
          font-size: 15px;
          color: $content-color;

          &.price {
            color: $content-color;
            margin-left: auto;

            body.rtl & {
              margin-left: unset;
              margin-right: auto;
            }

            .shpping-contain {
              display: block;
              font-weight: 400;
              font-size: 13px;
              margin-top: 3px;
            }
          }

          span {
            font-weight: 600;
          }
        }
      }
    }
  }

  .summery-contain {
    padding: calc(11px + (16 - 11) * ((100vw - 320px) / (1920 - 320))) calc(11px + (22 - 11) * ((100vw - 320px) / (1920 - 320)));
    border-bottom: 1px solid $border-color;

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--theme-color);
      border-radius: 4px;
    }

    li {
      padding: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320))) 0;
      display: flex;
      align-items: center;

      &:last-child {
        border-bottom: none;
      }

      p {
        font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
        line-height: 1.6;
        margin: 0;
      }

      h4 {
        font-size: 15px;
        color: $content-color;

        &.price {
          color: $content-color;
          margin-left: auto;

          body.rtl & {
            margin-left: unset;
            margin-right: auto;
          }

          .shpping-contain {
            display: block;
            font-weight: 400;
            font-size: 13px;
            margin-top: 3px;
          }
        }

        span {
          font-weight: 600;
        }
      }
    }
  }

  .summery-total {
    padding: 0 calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));

    li {
      padding-top: 12px;
      display: flex;
      align-items: center;

      h4 {
        font-size: 17px;
        color: $title-color;

        &.price {
          margin-left: auto;

          body.rtl & {
            margin-left: unset;
            margin-right: auto;
          }
        }

        span {
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 600;
        }
      }

      &:nth-child(4) {
        padding: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320))) 0;

        h4 {
          font-weight: 600;
          color: var(--theme-color);
        }
      }

      &:last-child {
        border-top: 1px solid $border-color;
        padding: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320))) 0;

        h4 {
          font-weight: 600;
          font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }
  }
}

.button-group {
  text-align: right;
  margin-top: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));

  &.cart-button {
    margin-top: 0;
    padding: 0 calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320))) calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));

    ul {
      body.rtl & {
        padding-right: 0;
      }

      li {
        width: 100%;
      }
    }
  }

  ul {
    @include flex_common($dis: flex, $align: center, $justify: flex-end);
    flex-wrap: wrap;
    gap: calc(7px + (16 - 7) * ((100vw - 320px) / (1920 - 320)));

    body.rtl & {
      padding-right: 0;
    }

    &.button-group-list {
      @include flex_common($dis: flex, $align: center, $justify: space-between);
      margin-top: calc(26px + (45 - 26) * ((100vw - 320px) / (1920 - 320)));
    }

    li {
      a {
        width: 100%;
        font: inherit;
        letter-spacing: 0.04em;
        padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320))) calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));

        &.shopping-button {
          font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
          background-color: $border-color;
          color: $black;

          i {
            margin-right: 8px;

            body.rtl & {
              margin-right: unset;
              margin-left: 8px !important;
            }
          }
        }
      }
    }
  }
}

.checkout-section {
  .custom-accordion {
    @include flex_wrap($dis: flex, $wrap: wrap, $gap: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320))));
    align-items: center;

    .accordion-item {
      border: none;
      border-radius: 6px;
      overflow: hidden;
      display: block;
      width: 100%;

      .accordion-header {
        .accordion-button {
          color: $content-color;
          position: relative;
          padding: 0;

          &::after {
            content: none;
          }

          &::before {
            @include pos($pos: absolute, $content: "\f107");
            @include font;
            @include center(vertical);
            right: 30px;
            transition: transform 0.2s ease-in-out;

            body.rtl & {
              right: unset;
              left: 30px;
            }
          }

          &:not(.collapsed) {
            color: $title-color;
            background-color: transparent;
            box-shadow: none;

            &::before {
              top: 20%;
              transform: rotate(-180deg) translateY(-50%);
            }
          }

          .form-check {
            display: flex;
            align-items: center;
            width: 100%;

            .form-check-label {
              font-weight: 500;
              color: $title-color;
              display: flex;
              align-items: center;
              width: 100%;
              font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
              padding: 16px 20px;

              body.rtl & {
                padding-right: 0;
              }

              @include mq-max(xs) {
                margin-top: 5px;
              }

              .form-check-input {
                margin-right: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

                body.rtl & {
                  margin-left: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
                  margin-right: unset;
                }
              }
            }
          }
        }
      }

      .accordion-collapse {
        .accordion-body {
          .cod-review {
            a {
              &:hover {
                color: var(--theme-color);
              }
            }
          }

          .custom-form-check {
            margin-bottom: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320)));
            display: flex;
            align-items: center;

            body.rtl & {
              padding-left: unset;
              padding-right: 0;
            }

            label {
              display: flex;
              align-items: center;
              font-size: calc(14px + (17 - 14) * ((100vw - 320px) / (1920 - 320)));
            }

            input {
              margin-right: 10px;

              body.rtl & {
                margin-right: unset;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  .custom-navtab {
    @include flex_wrap($dis: flex, $wrap: wrap, $gap: calc(0px + (24 - 0) * ((100vw - 320px) / (1920 - 320))));
    overflow: auto;
    position: sticky;
    top: 0;

    @include mq-max(lg) {
      display: flex;
      flex-wrap: nowrap;
    }

    .nav-item {
      width: 100%;
      flex-basis: auto;

      @include mq-max(lg) {
        display: inline-block;

        +.nav-item {
          margin-left: 15px;
        }
      }

      .nav-link {
        position: relative;
        text-align: left;
        border: 2px solid $border-color;
        padding: calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320)));
        cursor: pointer;

        @include mq-max(lg) {
          width: calc(195px + (220 - 195) * ((100vw - 320px) / (1920 - 320)));
        }

        &::before {
          @include pos;
          @include pseudowh($width: 0px, $height: 100%);
          bottom: 0;
          left: 0;
          background-color: var(--theme-color);
          background-color: transparent;
          transition: 0.3s ease;
          border-radius: 4px;
        }

        &.active {
          color: $title-color;
          background-color: transparent;
          border: 2px solid var(--theme-color);

          &::before {
            box-shadow: 0 4px 0px var(--theme-color);
            width: 100%;
          }

          .nav-item-box {

            h4,
            i {
              color: var(--theme-color);
              z-index: 1;
              -webkit-text-stroke: 1px transparent;
            }
          }
        }

        .nav-item-box {
          position: relative;
          display: flex;
          align-items: center;

          span {
            color: $content-color;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: calc(0px + (5 - 0) * ((100vw - 320px) / (1920 - 320)));
          }

          h4 {
            color: $title-color;
            font-weight: 500;
            font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
          }

          .lord-icon {
            @include pseudowh($width: calc(38px + (58 - 38) * ((100vw - 320px) / (1920 - 320))), $height: auto);
            margin-left: auto;

            body.rtl & {
              margin-left: unset;
              margin-right: auto;
            }
          }
        }
      }
    }
  }

  .tab-content {
    background-color: $light-gray;
    height: 100%;
    padding: calc(26px + (35 - 26) * ((100vw - 320px) / (1920 - 320))) calc(12px + (25 - 12) * ((100vw - 320px) / (1920 - 320)));

    .tab-pane {
      .tab-title {
        margin-bottom: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
        color: $title-color;
      }
    }
  }

  // Delivery Address
  .delivery-address-box {
    border-radius: 8px;
    padding: calc(12px + (24 - 12) * ((100vw - 320px) / (1920 - 320)));
    background-color: $white;
    box-shadow: 0 0 9px rgba($black, 0.07);

    >div {
      display: flex;
      position: relative;

      .label {
        position: absolute;
        top: 0;
        right: 0;
        background-color: var(--theme-color);
        padding: 2px 8px;
        border-radius: 4px;
        color: $white;
        font-size: 12px;
        letter-spacing: 0.8px;

        body.rtl & {
          right: unset;
          left: 0;
        }
      }

      .form-check {
        .form-check-input {
          @include pseudowh($width: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320))),
            $height: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320))));
          margin-top: 2px;

          &:checked {
            background-color: var(--theme-color);
            border-color: var(--theme-color);
          }

          &:checked[type="radio"] {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
          }

          &:focus {
            box-shadow: none;
          }
        }
      }

      .delivery-address-detail {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 10px);
        margin-left: 10px;
        width: calc(85% + (75 - 85) * ((100vw - 320px) / (1920 - 320)));

        body.rtl & {
          margin-left: unset;
          margin-right: 10px;
          padding-right: 0;
        }

        li {
          display: block;
          width: 100%;
        }

        p,
        h6 {
          line-height: 1.4;
          margin-bottom: 0;
          font-weight: 400;
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

          span {
            margin-right: 4px;
          }
        }
      }
    }
  }

  .add-address {
    @include flex_common;
    padding: 30px;
    height: 100%;
    text-align: center;
    cursor: pointer;
    background-color: $white;
    box-shadow: 0 0 9px rgba($black, 0.07);
    border-radius: 8px;
  }

  // Delivery Option
  .delivery-option {
    padding: calc(17px + (26 - 17) * ((100vw - 320px) / (1920 - 320)));
    background-color: $white;
    border-radius: 5px;

    .select-option {
      @include mq-max(3xl) {
        margin-top: 6px;
      }
    }

    .date-box {
      position: relative;

      i {
        @include center(vertical);
        position: absolute;
        right: 17px;
        font-size: 18px;
        color: $content-color;
      }
    }

    .delivery-category {
      display: flex;
      align-items: center;
      height: 100%;

      @include mq-max(md) {
        display: block;
      }
    }

    .custom-form-check {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      min-height: auto;

      body.rtl & {
        padding-left: unset;
        padding-right: 1.5rem;
      }

      .form-check-label {
        font-size: calc(15px + (19 - 15) * ((100vw - 320px) / (1920 - 320)));
        padding-left: 12px;
        font-weight: 500;

        body.rtl & {
          padding-left: unset;
          padding-right: 12px;
        }
      }
    }

    .delivery-items {
      display: flex;
      align-items: center;
      height: 100%;

      @include mq-max(sm) {
        display: flex;
        align-items: center;
      }

      @include mq-max(xs) {
        display: block;
      }

      h5 {
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;

        &.items {
          margin-bottom: 5px;

          @include mq-max(sm) {
            margin-bottom: 0;
          }

          @include mq-max(xs) {
            margin-bottom: 5px;
          }

          span {
            text-decoration: underline;
          }
        }

        &.charge {
          @include mq-max(sm) {
            margin-top: 8px;
          }
        }

        i {
          font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
          margin-left: 7px;
          color: rgba($black, 0.25);

          body.rtl & {
            margin-left: unset;
            margin-right: 7px;
          }
        }
      }
    }

    .delivery-date {
      font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 500;
      border: 1px solid $border-color;
      background-color: $white;
      display: flex;
      align-items: center;
      color: $content-color;
      width: 100%;
      padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));

      input {
        font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 500;
        border: none;
        background-color: $white;
        display: flex;
        align-items: center;
        color: $content-color;
        width: 100%;
      }
    }

    .delivery-time {
      display: flex;
      align-items: center;
      height: 100%;

      .dropdown-toggle {
        font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 500;
        border: 1px solid $border-color;
        background-color: $white;
        display: flex;
        align-items: center;
        color: $content-color;

        i {
          -webkit-text-stroke: 1px $content-color;
          color: transparent;
          font-size: 18px;
          margin-right: 10px;
        }

        &::after {
          content: none;
        }

        &::before {
          @include pos($pos: absolute, $content: "\f107");
          @include font;
          @include center(vertical);
          right: 20px;
        }
      }

      .dropdown-menu {
        border: 1px solid transparent;
        box-shadow: 0 6px 5px rgba($black, 0.1);

        li {
          a {
            &:active {
              color: $black;
              text-decoration: none;
              background-color: $border-color;
            }
          }

          +li {
            margin-top: 5px;
          }
        }
      }
    }
  }

  .payment-button {
    padding: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320))) calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
  }

  // Payment Method
  .payment-method {
    .custom-form-check {
      label {
        font-weight: 500;
        font-size: 17px;
      }
    }
  }

  .credit-card-box {
    .credit-detail {
      position: relative;
    }
  }

  .credit-info {
    label {
      font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 500;
    }
  }

  .payment-option {
    padding: 20px;
    box-shadow: 0 0 5px rgba($black, 0.17);
    border-radius: 8px;
    position: relative;

    .form-check {
      .form-check-input {
        margin-top: 4px;
      }

      label {
        font-weight: 500;
        font-size: 18px;
        padding-left: 10px;
      }
    }

    img {
      @include center(vertical);
      position: absolute;
      right: 20px;
      width: 60px;
    }
  }
}

/**=====================
     Contact Us Scss
==========================**/
.contact-box-section {
  .left-sidebar-box {
    .contact-image {
      text-align: center;
      margin-bottom: calc(25px + (64 - 25) * ((100vw - 320px) / (1920 - 320)));

      @include mq-max(xl) {
        margin-bottom: 0;
        @include flex_common;
        @include pseudowh;
      }

      @include mq-max(lg) {
        margin-bottom: calc(25px + (64 - 25) * ((100vw - 320px) / (1920 - 320)));
      }

      img {
        width: 60%;

        @include mq-max(xl) {
          width: 77%;
        }

        @include mq-max(lg) {
          width: 60%;
          margin-bottom: calc(25px + (64 - 25) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }

    .contact-title {
      margin-bottom: calc(20px + (32 - 20) * ((100vw - 320px) / (1920 - 320)));

      h3 {
        position: relative;
        display: inline-block;
        font-size: calc(23px + (28 - 23) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;

        &::after {
          @include pos;
          @include pseudowh($width: 70%, $height: 2px);
          bottom: -5px;
          left: 0;
          background: var(--theme-color);

          body.rtl & {
            left: unset;
            right: 0;
          }
        }
      }
    }

    .contact-detail {
      .contact-detail-box {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: calc(2px + (5 - 2) * ((100vw - 320px) / (1920 - 320))));
        align-items: center;
        background-color: $light-gray;
        padding: calc(15px + (27 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (27 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (27 - 15) * ((100vw - 320px) / (1920 - 320))) calc(30px + (41 - 30) * ((100vw - 320px) / (1920 - 320)));
        position: relative;
        border-radius: 10px;
        margin-left: 22px;

        body.rtl & {
          padding: calc(15px + (27 - 15) * ((100vw - 320px) / (1920 - 320)))
          calc(30px + (41 - 30) * ((100vw - 320px) / (1920 - 320)))
            calc(15px + (27 - 15) * ((100vw - 320px) / (1920 - 320)))
            calc(15px + (27 - 15) * ((100vw - 320px) / (1920 - 320)));
            margin-left: 0;
            margin-right: 22px;
        }

        .contact-icon {
          @include center(vertical);
          position: absolute;
          left: -21px;
          background-color: var(--theme-color);
          border-radius: 7px;
          display: inherit;
          color: $white;
          width: 42px;
          height: 42px;
          @include flex_common;

          body.rtl & {
            left: unset;
            right: -21px;
          }

          i {
            line-height: 1;
            font-size: 18px;
          }
        }

        .contact-detail-title,
        .contact-detail-contain {
          width: 100%;
        }

        .contact-detail-title {
          h4 {
            font-weight: 600;
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            margin-top: -2px;
          }
        }

        .contact-detail-contain {
          p {
            margin: 0;
            font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
            line-height: 1.5;
            color: $content-color;
            margin-bottom: -5px;
          }
        }
      }
    }
  }

  .right-sidebar-box {
    padding: calc(21px + (60 - 21) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 10px;
    background-color: $light-gray;

    .custom-form {
      label {
        color: $content-color;
        display: block;
        font-size: 16px;
        margin-bottom: calc(3px + (8 - 3) * ((100vw - 320px) / (1920 - 320)));
      }

      .custom-input {
        position: relative;

        .form-control {
          padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))) calc(41px + (52 - 41) * ((100vw - 320px) / (1920 - 320)));
          border: none;

          body.rtl & {
            padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))) calc(41px + (52 - 41) * ((100vw - 320px) / (1920 - 320))) calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
          }

          &:focus {
            background-color: $white;
            border-color: transparent;
          }
        }

        i {
          position: absolute;
          top: 22px;
          left: 0;
          padding: 0 calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
          display: flex;
          align-items: center;
          font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
          color: $content-color;
          line-height: 1;

          body.rtl & {
            left: unset;
            right: 0;
          }
        }
      }

      .custom-textarea {
        position: relative;

        .form-control {
          padding-left: 52px;
          border: none;

          body.rtl & {
            padding-left: unset;
            padding-right: 52px;
          }

          &:focus {
            background-color: $white;
            border-color: transparent;
          }
        }

        i {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          padding: 18px 20px;
          line-height: 1;
          display: flex;
          font-size: 18px;
          color: $content-color;

          body.rtl & {
            left: unset;
            right: 0;
          }
        }
      }
    }
  }
}

.map-section {
  .map-box {
    margin-bottom: -6px;
    display: block;

    iframe {
      @include pseudowh($width: 100%, $height: 350px);
    }
  }
}

/**=====================
     Log In Us Scss
==========================**/
.log-in-section {
  overflow-x: hidden;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;

  &::after {
    @include pos;
    @include pseudowh;
    top: 0;
    left: 0;
    background-image: url("../../images/inner-page/log-in-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  &.otp-section {
    display: flex;
    align-items: center;
  }

  .image-contain {
    @include flex_common;
    height: 100%;
  }

  .log-in-form {
    .form-control {
      border: none;
    }
  }

  .inputs {
    margin: calc(-2px + (-8 - 2) * ((100vw - 320px) / (1920 - 320)));

    input {
      margin: calc(2px + (8 - 2) * ((100vw - 320px) / (1920 - 320)));
      border: none;
    }
  }

  .log-in-box {
    background-color: $light-gray;
    padding: calc(16px + (50 - 16) * ((100vw - 320px) / (1920 - 320)));
    border-radius: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
    width: 100%;

    .logo-name {
      margin-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
      margin-top: -10px;

      a {
        font-size: calc(28px + (35 - 28) * ((100vw - 320px) / (1920 - 320)));
        font-weight: bold;
        color: $title-color;
        font-family: $public-sans;
        display: block;

        img {
          width: calc(150px + (225 - 150) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }

    .log-in-title {
      margin-bottom: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));

      h3 {
        margin-bottom: calc(1px + (6 - 1) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(18px + (21 - 18) * ((100vw - 320px) / (1920 - 320)));
      }

      h4,
      h5 {
        color: $content-color;
        margin-top: 8px;
      }
    }

    .log-in-button {
      margin-top: 20px;
      padding: 1px 0;

      ul {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 15px);
        align-items: center;

        body.rtl & {
          padding-right: 0;
        }

        li {
          display: block;
          width: 100%;

          a {
            @include flex_common;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: $title-color;
            border-radius: 5px;
            padding: calc(10px + (16 - 10) * ((100vw - 320px) / (1920 - 320))) calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
            flex-wrap: wrap;
            gap: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
            background-color: $white;

            img {
              width: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
            }
          }
        }
      }
    }

    .forgot-box {
      @include flex_common($dis: flex, $align: center, $justify: space-between);

      @include mq-max(2xs) {
        display: block;
      }
    }

    .remember-box {
      display: flex;
      align-items: center;

      .check-box {
        display: block;
        margin-top: -6px;
      }

      label {
        font-size: 16px;

        span {
          font-weight: 500;
          color: var(--theme-color);
        }
      }
    }

    .forgot-password {
      font-size: 16px;
      font-weight: 500;
      display: block;

      &:hover {
        color: var(--theme-color);
      }
    }

    .other-log-in {
      margin-top: 15px;
      text-align: center;
      position: relative;

      &::before {
        @include pos;
        @include center(vertical);
        @include pseudowh($width: 100%, $height: 1px);
        left: 0;
        background-color: #ddd;
        z-index: 0;
      }

      h6 {
        color: $content-color;
        position: relative;
        background-color: #f9f9f9;
        padding: 0 14px;
        display: inline-block;
        text-transform: uppercase;
      }
    }

    .sign-up-box {
      margin-top: 20px;
      text-align: center;

      h4 {
        color: $content-color;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: calc(0px + (3 - 0) * ((100vw - 320px) / (1920 - 320)));
      }

      a {
        font-weight: 500;
        font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: -4px;
        display: block;

        &:hover {
          color: var(--theme-color);
        }
      }
    }

    .contact-title {
      margin-bottom: 30px;

      h2 {
        margin-bottom: 15px;
      }

      h5 {
        width: 53%;
        font-size: 18px;
        line-height: 1.3;
        color: $content-color;
      }
    }

    .phone-field {
      .form-floating>label {
        left: 105px;
        width: calc(100% - 105px);

        body.rtl & {
          left: unset;
          right: 105px;
        }
      }

      .form-control {
        padding-left: 112px !important;

        body.rtl & {
          padding-left: unset !important;
          padding-right: 112px !important;
        }
      }

      .custom-select {
        &.intl-tel-input {
          top: 0;
          left: 0;
          width: 104px;
          border: none;
          border-radius: 6px 0 0 6px;
          border-right: 1px solid $border-color;

          body.rtl & {
            left: unset;
            right: 0;
            border-radius: 0 6px 6px 0;
          }
        }

        .select2-container {
          .select2-selection {
            border-radius: 6px 0 0 6px;
            border: unset !important;
            min-width: unset !important;
            width: unset !important;
            padding: 14px 12px !important;
            background-color: $white !important;
            
            .country {
              background-color: $white !important;
            }

            body.rtl & {
              border: unset !important;
              border-radius: 0 6px 6px 0;
            }
          }

          .select2-container-dropdown {
            &.select2-container--open {
              .select2-dropdown {
                box-shadow: unset;
              }
            }
          }
        }
      }
    }
  }
}

/**=====================
     FAQ Scss
==========================**/
.faq-contain {
  margin-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));

  .faq-top-box {
    text-align: center;
    background-color: $light-gray;
    border-radius: 9px;
    padding: 28px 18px;

    .faq-box-icon {
      @include pseudowh($width: 65px, $height: 65px);
      @include flex_common;
      background-color: $white;
      border-radius: 7px;
      margin: 0 auto 16px;

      img {
        @include pseudowh($width: calc(36px + (45 - 36) * ((100vw - 320px) / (1920 - 320))), $height: auto);
      }
    }

    .faq-box-contain {
      h3 {
        font-weight: 700;
      }

      p {
        margin: 17px 0 0;
        line-height: 1.6;
        color: $content-color;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        margin-bottom: -4px;
      }
    }
  }
}

.faq-box-contain {
  position: relative;
  z-index: 0;

  .faq-contain {
    margin-bottom: 0;
    position: sticky;
    top: 92px;

    @include mq-max(xl) {
      margin-bottom: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
    }

    h2 {
      font-weight: 700;
      font-size: calc(28px + (56 - 28) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.4;
    }

    p {
      margin: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320))) 0 0;
      font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.5;
      color: $content-color;

      @include mq-max(xl) {
        width: 70%;
      }

      @include mq-max(lg) {
        width: 90%;
      }

      @include mq-max(md) {
        width: 100%;
        text-align: justify;
      }
    }
  }

  .faq-accordion {
    .accordion {
      @include flex_wrap($dis: flex, $wrap: wrap, $gap: calc(15px + (24 - 15) * ((100vw - 320px) / (1920 - 320))));

      .accordion-item {
        width: 100%;
        border-radius: 7px;
        background-color: $light-gray;
        border: none;
        overflow: hidden;

        .accordion-header {
          .accordion-button {
            background-color: $light-gray;
            font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 500;
            line-height: 1.5;
            padding: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320))) calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));

            i {
              margin-left: auto;
              transition: all 0.3s ease-in-out;

              body.rtl & {
                margin-left: unset;
                margin-right: auto;
              }
            }

            &::after {
              content: unset;
            }

            &:not(.collapsed) {
              font-weight: 700;
              color: var(--theme-color);
              background-color: transparent;
              box-shadow: none;

              i {
                transform: rotate(180deg);
              }
            }

            &:focus {
              border-color: transparent;
              box-shadow: none;
            }
          }
        }

        .accordion-collapse {
          .accordion-body {
            padding: 0 calc(12px + 8 * (100vw - 320px) / 1600) 13px;

            .accordion-body {
              padding: 0;
            }

            p {
              font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
              line-height: 1.6;
              color: $content-color;
              margin-bottom: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));

              &:last-child {
                margin-bottom: -4px;
              }
            }
          }
        }
      }
    }
  }
}

/**=====================
     Compare Scss
==========================**/
.compare-section {
  .compare-table {
    border: 1px solid #ddd;
    margin-bottom: 0;

    tr {

      td,
      th {
        min-width: 200px;
        border-right: 1px solid #ddd;
        padding: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320))) calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
        text-align: center;

        &:first-child {
          min-width: auto;
        }
      }

      th {
        font-size: 16px;
        font-weight: 500;
        color: var(--theme-color);
        background-color: $light-gray;
      }

      td {
        font-size: 15px;

        &.title {
          a {
            font-weight: 700;
            color: #222;
          }
        }

        a {
          font-weight: 600;

          &:hover {
            color: $title-color;
          }
        }

        .compare-image {
          @include pseudowh($width: calc(105px + (130 - 105) * ((100vw - 320px) / (1920 - 320))),
            $height: calc(105px + (130 - 105) * ((100vw - 320px) / (1920 - 320))));
          @include flex_common;
          text-align: center;
          border-radius: 5px;
          background-color: #f1f1f1;
          margin: 0 auto;

          img {
            @include pseudowh($width: 100%, $height: calc(65px + (90 - 65) * ((100vw - 320px) / (1920 - 320))));
            object-fit: contain;
          }
        }

        .price {
          h5 {
            font-weight: 600;
            color: $title-color;

            del {
              font-weight: 400;
              font-size: 15px;
              margin-left: 4px;
              color: #777;
            }

            span {
              margin-left: 4px;
              color: var(--theme-color);
            }
          }
        }

        .compare-rating {
          @include flex_common;
          flex-wrap: wrap;
          gap: 5px;

          span {
            font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));

            body.rtl & {
              margin-left: unset;
              padding-right: 5px;
            }
          }
        }

        .summary {
          color: #777;
          line-height: 1.5;
          margin-bottom: 0;
          font-size: 15px;
        }
      }
    }
  }

  .compare-part {
    position: relative;
    border: 1px solid #eee;

    .detail-part {
      .title-detail {
        background-color: #f7f8fa;
        text-align: center;
        padding: 14px 0;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
      }

      .inner-detail {
        padding: 15px;
        text-align: center;

        p {
          margin-bottom: 0;
          line-height: 1.2;
          letter-spacing: 0.05em;
        }
      }
    }

    .img-section {
      padding-top: 20px;

      img {
        width: calc(105px + 25 * (100vw - 320px) / 1600);
        height: calc(105px + 25 * (100vw - 320px) / 1600);
        @include flex_common;
        text-align: center;
        border-radius: 5px;
        margin: 0 auto;
        object-fit: contain;
      }

      h5 {
        text-align: center;
        margin: 12px 0;
        font-size: 17px;
        text-transform: capitalize;
        font-weight: 500;
      }
    }

    .compare-rating {
      span {
        color: #fea348;
        font-size: 18px;
      }

      .rating-text {
        font-size: 16px;
        color: $content-color;
        margin-left: 6px;
      }
    }

    .btn-part {
      padding: 15px;
      border-top: 1px solid #dbdbdb;
      border-bottom: 1px solid #dbdbdb;

      .btn {
        margin: 0 auto;
        width: max-content;
      }
    }

    .remove-part {
      padding: 15px;
      text-align: center;
    }
  }

  .compare-row {
    >div {
      .compare-part {
        border-left: none;
      }

      &:first-child {
        .compare-part {
          border: 1px solid #eee;
        }
      }
    }
  }
}

.compare-fix {
  a {
    position: fixed;
    right: calc(54px + (72 - 54) * ((100vw - 320px) / (1920 - 320)));
    background-color: var(--theme-color);
    color: $white;
    padding: calc(7px + (12 - 7) * ((100vw - 320px) / (1920 - 320))) calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320))) calc(6px + (12 - 6) * ((100vw - 320px) / (1920 - 320)));
    bottom: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 6px;
    z-index: 0;
    transition: all 0.3s ease-in-out;

    @include mq-max(md) {
      bottom: calc(75px + (76 - 75) * ((100vw - 320px) / (767 - 320)));
    }

    body.rtl & {
      left: calc(54px + (72 - 54) * ((100vw - 320px) / (1920 - 320)));
      right: unset;
    }
  }

  h5 {
    font-size: 14px;
    text-transform: uppercase;
  }
}

.skeleton-compare {
  .compare-part {
    .img-section {
      text-align: center;

      .compare-img {
        background: #f6f7f8;
        border-radius: 7px;
        width: calc(105px + (130 - 105) * ((100vw - 320px) / (1920 - 320)));
        height: calc(105px + (130 - 105) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 5px;
        margin: 0 auto;
      }

      h5 {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .placeholder {
    background: #f6f7f8;
    background-position: -500px 0;
    animation: skeletonShine 1s linear 0s infinite normal forwards;
    background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 1000px 100%;
    border-radius: 7px;
    opacity: 1;
  }
}

/**=====================
     blog Scss
==========================**/
.blog-section {
  .left-sidebar-box {
    position: sticky;
    top: 20px;

    .left-search-box {
      .search-box {
        position: relative;

        &::before {
          @include pos($pos: absolute, $content: "\f002");
          @include font;
          @include center(vertical);
          right: 20px;
          color: $content-color;
        }

        &::after {
          @include pos;
          @include center(vertical);
          @include pseudowh($width: 1px, $height: 60%);
          right: 51px;
          opacity: 0.3;
          background-color: $content-color;
        }

        input {
          padding: 8px 68px 8px 20px;
          background-color: $light-gray;
          border: none;
          height: 53px;
          border-radius: 7px;

          &:focus {
            border-color: #ced4da;
          }
        }
      }
    }

    .left-accordion-box {
      .accordion-item {
        border: none;
        padding: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
        background-color: $light-gray;
        border-radius: 8px;

        &.skeleton-accordion {
          background-color: transparent;

          .accordion-collapse {
            background-color: transparent;
          }

          .accordion-header {
            .accordion-button {
              background-color: transparent;

              span {
                background: #f6f7f8;
                background-position: -500px 0;
                animation: skeletonShine 1s linear 0s infinite normal forwards;
                background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
                background-repeat: no-repeat;
                background-size: 1000px 100%;
                width: 92px;
                height: 18px;
                border-radius: 9px;

                &:before {
                  display: none;
                }
              }

              &::after {
                display: none;
              }
            }
          }

          .placeholder {
            background: #f6f7f8;
            background-position: -500px 0;
            animation: skeletonShine 1s linear 0s infinite normal forwards;
            background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
            background-repeat: no-repeat;
            background-size: 1000px 100%;
            border-radius: 9px;
            opacity: 1;
            height: 20px;
            position: relative;
            margin-left: 30px;

            &::after {
              @include pos;
              left: 0;
              width: 20px;
              height: 100%;
              left: -30px;
              background-color: #f6f7f8;
              background-position: -500px 0;
              animation: skeletonShine 1s linear 0s infinite normal forwards;
              background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
              background-repeat: no-repeat;
              background-size: 1000px 100%;
              top: auto;
              border-radius: 5px;
            }
          }

          .accordion-body {
            ul {
              display: flex;
              flex-direction: column;
              gap: 18px;
            }
          }
        }

        +.accordion-item {
          margin-top: 20px;
        }

        .accordion-header {
          .accordion-button {
            background-color: $light-gray;
            color: $title-color;
            font-weight: 700;
            padding: 0;

            &::after {
              @include pseudowh($width: unset, $height: unset);
              @include remixicon;
              background-image: none;
              content: "\ea4e";

              body.rtl & {
                margin-left: unset;
                margin-right: auto;
              }
            }

            &:focus {
              border-color: transparent;
              box-shadow: none;
            }

            &:not(.collapsed) {
              box-shadow: none;
            }
          }
        }

        .accordion-collapse {
          background-color: $light-gray;

          .accordion-body {
            padding: 0;
            margin-top: 20px;

            .recent-post-box {
              .recent-box {
                display: flex;
                align-items: center;

                +.recent-box {
                  margin-top: 20px;
                }

                &:hover {
                  .recent-image {
                    img {
                      transform: scale(1.1) rotate(4deg);
                    }
                  }
                }

                .recent-image {
                  width: 110px;
                  border-radius: 5px;
                  overflow: hidden;

                  img {
                    @include pseudowh;
                    transition: all 0.3s ease-in-out;
                  }
                }

                .recent-detail {
                  padding-left: 15px;
                  width: calc(100% - 110px);

                  body.rtl & {
                    padding-left: unset;
                    padding-right: 15px;
                  }

                  a {
                    color: $title-color;

                    &:hover {
                      color: $title-color;
                    }

                    h5 {
                      font-weight: 600;
                      line-height: 1.45;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      display: -webkit-box;
                      overflow: hidden;
                    }
                  }

                  h6 {
                    @include flex_common($dis: flex, $align: center, $justify: space-between);
                    margin-top: 8px;
                    color: $content-color;

                    .feather {
                      @include pseudowh($width: 18px, $height: 18px);
                      stroke-width: 1;
                    }
                  }
                }
              }
            }

            .category-list-box {
              ul {
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 14px);

                body.rtl & {
                  padding-left: unset;
                  padding-right: 0;
                }

                li {
                  display: block;
                  position: relative;
                  transition: all 0.3s ease-in-out;
                  z-index: 0;
                  overflow: hidden;
                  width: 100%;

                  &:first-child {
                    padding-top: 0;
                  }

                  a {
                    display: block;
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                    color: $title-color;

                    .category-name {
                      @include flex_common($dis: flex, $align: center, $justify: space-between);

                      h5 {
                        color: $content-color;
                        font-weight: 400;
                      }

                      h5,
                      span {
                        transition: all 0.3s ease-in-out;
                      }

                      span {
                        @include pseudowh($width: 22px, $height: 22px);
                        @include flex_common;
                        font-size: 15px;
                        padding: 4px 5px 3px;
                        border-radius: 100%;
                        color: #787878;
                      }
                    }
                  }
                }
              }
            }

            .product-tags-box {
              ul {
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 10px);

                body.rtl & {
                  padding-left: unset;
                  padding-right: 0;
                }

                li {
                  z-index: 0;
                  padding: 4px 9px;
                  border-radius: 3px;
                  transition: all 0.3s ease-in-out;
                  overflow: hidden;
                  position: relative;
                  background-color: $white;

                  &::after {
                    @include pos;
                    @include pseudowh;
                    transition: all 0.3s ease-in-out;
                    z-index: -1;
                    top: 0;
                    left: 0;
                    background-color: var(--theme-color);
                    opacity: 0;
                  }

                  &:hover {
                    border-color: transparent;

                    &::after {
                      opacity: 1;
                    }

                    a {
                      color: $white;
                    }
                  }

                  a {
                    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
                    color: $content-color;
                    text-transform: capitalize;
                  }
                }
              }
            }

            .product-list-2 {
              li {
                +li {
                  margin-top: 30px;

                  &::after {
                    top: -16px;
                    width: calc(100% - 80px - calc(12px + (23 - 12) * ((100vw - 320px) / (1920 - 320))));
                  }
                }

                .offer-product {
                  .offer-detail {
                    a {
                      color: $title-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .custom-border {
    border-bottom: 1px solid $border-color;
    padding-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(35px + (60 - 35) * ((100vw - 320px) / (1920 - 320)));
  }

  .blog-box {
    height: 100%;

    &:hover {
      &::after {
        transform: unset;
        opacity: 0.1;
      }

      .blog-image {
        a {
          transform: scale(1.1) rotate(4deg);
        }
      }
    }

    .blog-image {
      position: relative;
      overflow: hidden;
      margin-bottom: 15px;
      border-radius: 0;
      height: 244px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      a {
        transition: all 0.3s ease-in-out;
      }

      label {
        @include flex_common;
        position: absolute;
        top: 0;
        bottom: unset;
        left: 0;
        border-radius: 0;
        background-color: #febc5c;
        color: $white;
        padding: 10px 13px;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        display: none;

        i {
          @include pseudowh($width: 16px, $height: 16px);
          object-fit: contain;
          margin-right: 3px;
          color: $white;
        }
      }
    }

    .blog-contain {
      padding: 0 20px 20px;

      &-2 {
        padding: 0;
      }

      .blog-label {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 2px 15px);
        align-items: center;

        .time,
        .super {
          display: flex;
          align-items: center;
          color: $content-color;
          text-transform: uppercase;
          font-size: 13px;
          gap: 5px;

          i {
            font-size: 16px;
          }

          .feather {
            @include pseudowh($width: 16px, $height: 16px);
            object-fit: contain;
            margin-right: 4px;

            body.rtl & {
              margin-right: unset;
              margin-left: 4px;
            }
          }

          span {
            margin-top: 1px;
          }
        }
      }

      a {
        color: $title-color;

        &:hover {
          color: $title-color;
        }

        h3 {
          margin: 4px 0 6px;
          font-weight: 600;
          line-height: 1.5;
          text-transform: capitalize;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
          transition: all 0.3s ease-in-out;
        }
      }

      p {
        line-height: 1.8;
        color: $content-color;
        margin-bottom: 0;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;

        @include mq-max(2xl) {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
        }
      }

      .blog-button {
        border: none;
        font-size: 16px;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 0;
        overflow: hidden;
        border-radius: 5px;
        padding: 10px 22px;
        color: var(--theme-color);
        transition: all 0.3s ease-in-out;
        margin-top: calc(8px + (22 - 8) * ((100vw - 320px) / (1920 - 320)));
        font-size: 14px;
        width: max-content;

        &:after {
          @include pos;
          background-color: var(--theme-color);
          opacity: 0.1;
          left: 0;
          top: 0;
          @include pseudowh;
          z-index: -1;
        }

        &:hover {
          color: #fff;

          &:after {
            opacity: 1;
          }
        }

        i {
          margin-left: 10px;
          font-size: 20px;
          line-height: 1;

          body.rtl & {
            margin-left: unset;
            margin-right: 10px;
          }
        }
      }
    }

    &.sticky-blog-box {
      overflow: hidden;
      position: relative;
      z-index: 0;
      border-color: rgb(26, 164, 136,  0.28);

      &::before {
        @include pos($pos: absolute, $content: "\f038");
        @include remixicon;
        @include flex_common;
        @include pseudowh($width: 33px, $height: 33px);
        color: #ffffff;
        top: 8px;
        right: 8px;
        background-color: var(--theme-color);
        z-index: 1;
        border-radius: 6px;
        font-size: 18px;

        body.rtl & {
          left: 8px;
          right: unset;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
          -webkit-filter: FlipH;
          filter: FlipH;
        }
      }

      &::after {
        content: none;
      }
    }

    .blog-label-tag {
      position: absolute;
      top: 15px;
      left: 0;
      padding: 5px 13px;
      background-color: #ffba00;
      font-size: 14px;
      color: $white;
      font-weight: 500;
      z-index: 1;

      &::before {
        @include pos;
        @include pseudowh($width: 15px, $height: 15px);
        top: 0;
        right: -15px;
        border-bottom: solid 15px #ffba00;
        border-left: solid 15px transparent;
        border-right: solid 15px transparent;
        transform: rotate(180deg);
        z-index: 1;
      }

      &::after {
        @include pos;
        @include pseudowh($width: 15px, $height: 15px);
        top: 16px;
        right: -15px;
        border-bottom: solid 15px #ffba00;
        border-left: solid 15px transparent;
        border-right: solid 15px transparent;
        transform: rotate(0);
        z-index: 1;
      }

      span {
        font-weight: 700;
      }
    }
  }

  .blog-list {
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;

    @include mq-max(xl) {
      display: block;
    }

    @include mq-max(lg) {
      display: flex;
    }

    @include mq-max(md) {
      display: block;
    }

    &:hover {
      box-shadow: 0 4px 9px rgba($title-color, 0.1);

      .blog-image {
        img {
          transform: scale(1.1);
        }
      }

      .blog-contain {
        a {
          h3 {
            color: var(--theme-color);
          }
        }

        .blog-button {
          background-color: var(--theme-color);
          color: $white;
        }
      }
    }

    .blog-image {
      margin-bottom: -1px;
      width: 38%;
      transition: all 0.3s ease-in-out;
      height: calc(257px + (300 - 257) * ((100vw - 320px) / (1920 - 320)));

      @media (max-width: 1399px) {
        width: 45%;
      }

      @include mq-max(xl) {
        width: 100%;
      }

      @include mq-max(lg) {
        width: 45%;
      }

      @include mq-max(md) {
        @include pseudowh($width: 100%, $height: auto);
        margin-bottom: 8px;
        text-align: center;
      }

      img {
        transition: all 0.3s ease-in-out;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include mq-max(lg) {
          width: auto;
        }

        @include mq-max(md) {
          width: 100%;
        }
      }
    }

    .blog-contain {
      padding: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
      width: 62%;

      @media (max-width: 1399px) {
        width: 55%;
      }

      @include mq-max(xl) {
        width: 100%;
      }

      @include mq-max(lg) {
        width: 55%;
      }

      @include mq-max(md) {
        width: 100%;
      }

      p {
        line-height: 1.8;
        color: $content-color;
        margin-bottom: 0;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;

        @include mq-max(2xl) {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
        }
      }
    }
  }

  .blog-detail-image {
    position: relative;

    .blog-image-contain {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 55px 15px 40px;
      text-align: center;
      background: $white;
      background: linear-gradient(0deg, $white 0%, rgba(246, 246, 246, 0) 100%);
      color: $title-color;

      @include mq-max(xs) {
        position: relative;
      }

      .contain-list {
        li {
          +li {
            margin-left: 30px;

            body.rtl & {
              margin-left: unset;
              margin-right: 30px;
            }

            &::before {
              @include pos;
              @include pseudowh($width: 15px, $height: 1px);
              @include center(vertical);
              background-color: $title-color;
              left: -24px;

              body.rtl & {
                right: -24px;
                left: unset;
              }
            }
          }

          text-transform: capitalize;
          position: relative;
        }
      }

      h2 {
        margin: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320))) 0;
        font-weight: 700;
        font-size: calc(22px + (34 - 22) * ((100vw - 320px) / (1920 - 320)));
      }

      .contain-comment-list {
        @include flex_common;
        flex-wrap: wrap;
        gap: calc(10px + (23 - 10) * ((100vw - 320px) / (1920 - 320)));
        color: #777;

        li {
          .user-list {
            display: flex;
            align-items: center;

            i {
              font-size: 18px;
              margin-right: 3px;

              body.rtl & {
                margin-right: unset;
                margin-left: 3px;
              }
            }

            .feather {
              @include pseudowh($width: 18px, $height: 18px);
              margin-right: 3px;

              body.rtl & {
                margin-right: unset;
                margin-left: 3px;
              }
            }
          }
        }
      }
    }
  }

  .blog-detail-contain {
    p {
      color: $content-color;
      font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
      line-height: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));

      .first {
        @include pseudowh($width: calc(38px + (50 - 38) * ((100vw - 320px) / (1920 - 320))),
          $height: calc(38px + (50 - 38) * ((100vw - 320px) / (1920 - 320))));
        @include flex_common;
        font-size: calc(55px + (70 - 55) * ((100vw - 320px) / (1920 - 320)));
        color: $content-color;
        float: left;
        margin-right: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
        margin-top: 8px;
      }
    }

    .blog-details-quote {
      padding: calc(18px + (60 - 18) * ((100vw - 320px) / (1920 - 320))) calc(18px + (60 - 18) * ((100vw - 320px) / (1920 - 320))) calc(18px + (60 - 18) * ((100vw - 320px) / (1920 - 320))) calc(62px + (180 - 62) * ((100vw - 320px) / (1920 - 320)));
      border-radius: 10px;
      margin-bottom: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
      background-color: $content-color;
      position: relative;
      color: $white;

      h3 {
        font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(24px + (35 - 24) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
      }

      h5 {
        letter-spacing: 3px;
        color: #ddd;
        text-transform: uppercase;
      }

      &::after {
        @include pos($pos: absolute, $content: "\f10d");
        @include font;
        font-size: calc(38px + (70 - 38) * ((100vw - 320px) / (1920 - 320)));
        color: var(--theme-color);
        top: calc(-6px + (26 - -6) * ((100vw - 320px) / (1920 - 320)));
        left: calc(15px + (87 - 15) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }

  .leave-title {
    margin: 40px 0 9px;

    h3 {
      position: relative;
      font-weight: 700;
      z-index: 0;
      color: $content-color;
      font-size: 22px;
    }
  }

  .user-comment-box {
    margin-top: 24px;

    ul {
      display: flex;
      flex-wrap: wrap;

      body.rtl & {
        padding-left: unset;
        padding-right: 0;
      }

      li {
        padding: 0px 0 50px;
        width: 100%;
        position: relative;

        &.li-padding {
          padding-left: calc(28px + (80 - 28) * ((100vw - 320px) / (1920 - 320)));
        }

        @include mq-max(xs) {
          padding: 0;
        }

        .user-box {
          display: flex;
          align-items: flex-start;

          @include mq-max(xs) {
            display: block;
          }

          .reply-button {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;

            body.rtl & {
              right: unset;
              left: 0;
            }

            i {
              margin: -3px 9px 0 0;
              color: #777;

              body.rtl & {
                margin: -3px 0 0 9px;
              }
            }
          }

          .user-iamge {
            position: relative;

            img {
              @include pseudowh($width: 60px, $height: 60px);
              object-fit: cover;
              border-radius: 100%;
              overflow: hidden;
            }

            .user-name {
              position: absolute;
              top: 0;
              left: 80px;
              width: 220px;

              body.rtl & {
                right: 80px;
                left: unset;
              }

              @include mq-max(xs) {
                @include center(vertical);
              }

              h5 {
                font-weight: 600;
              }

              h6 {
                color: $content-color;
                margin-bottom: 6px;
              }
            }
          }

          .user-contain {
            width: calc(100% - 70px);
            padding-left: 20px;

            body.rtl & {
              padding-left: unset;
              padding-right: 20px;
            }

            @include mq-max(xs) {
              width: 100%;
              padding: 0;
              margin-top: 14px;
            }

            p {
              font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
              line-height: 1.6;
              color: $content-color;
              margin-bottom: 0;
              margin-top: 50px;
              width: 85%;

              @include mq-max(sm) {
                width: 100%;
              }

              @include mq-max(xs) {
                margin: 0 0 35px;
              }
            }
          }
        }
      }
    }
  }

  .leave-box {
    padding: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
    background-color: $light-gray;
    border-radius: 6px;

    .leave-comment {
      .comment-notes {
        p {
          line-height: 1.6;
        }
      }

      .save-comment-box {
        margin: 20px 0 0;
        display: flex;
        align-items: center;

        .form-check {
          display: flex;
          align-items: center;

          input {
            margin-top: -5px;

            @media (max-width: 659px) {
              margin-top: -21px;
            }

            @media (max-width: 341px) {
              margin-top: -44px;
            }
          }

          .form-check-label {
            font-size: 15px;
            font-weight: 400;
            color: $content-color;
          }
        }
      }

      .blog-input {
        .form-control {
          border: none;

          &:focus {
            background-color: $white;
          }
        }
      }
    }
  }
}

/**=====================
     About Us Scss
==========================**/
.review-title {
  h4 {
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(7px + (13 - 7) * ((100vw - 320px) / (1920 - 320)));
    color: $title-color;
    font-weight: 400;
  }

  h2 {
    font-size: calc(26px + (40 - 26) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(12px + (30 - 12) * ((100vw - 320px) / (1920 - 320)));
    width: 80%;
    line-height: 1.3;
    position: relative;

    @include mq-max(xs) {
      width: 100%;
    }

    &.center {
      &::before {
        @include center(horizontal);
        color: var(--theme-color);
      }
    }
  }
}

.about-us-title {
  h4 {
    font-size: calc(16px + (21 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(7px + (13 - 7) * ((100vw - 320px) / (1920 - 320)));
    color: var(--theme-color);
    font-family: $pacifico;
  }

  h2 {
    font-size: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(28px + (41 - 28) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.3;
    position: relative;

    &::before {
      @include pos;
      @include pseudowh($width: calc(106px + (175 - 106) * ((100vw - 320px) / (1920 - 320))), $height: 3px);
      bottom: calc(-7px + (6 - 7) * ((100vw - 320px) / (1920 - 320)));
      left: 0;
      background: #ffa53b;
    }

    &.center {
      &::before {
        @include center(horizontal);
      }
    }
  }
}

.fresh-vegetable-section {
  .fresh-image {
    margin-top: 30px;
    border-radius: calc(12px + (50 - 12) * ((100vw - 320px) / (1920 - 320))) 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    >div {
      transition: all 0.3s ease-in-out;
      overflow: hidden;
    }

    &:hover {
      >div {
        transform: scale(1.05);
      }
    }
  }

  .fresh-image-2 {
    border-radius: 0 calc(12px + (50 - 12) * ((100vw - 320px) / (1920 - 320)));
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    >div {
      transition: all 0.3s ease-in-out;
      overflow: hidden;
    }

    &:hover {
      >div {
        transform: scale(1.05);
      }
    }
  }

  .fresh-contain {
    height: 100%;

    p {
      font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.7;
      color: $content-color;
      margin-bottom: calc(11px + (20 - 11) * ((100vw - 320px) / (1920 - 320)));
    }
  }

  .delivery-list {
    ul {
      @include flex_wrap($dis: flex, $wrap: wrap, $gap: calc(11px + (20 - 11) * ((100vw - 320px) / (1920 - 320))));

      body.rtl & {
        padding-right: 0;
      }

      li {
        width: 100%;

        .deliver-box {
          @include flex_wrap($dis: flex, $wrap: nowrap, $gap: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320))));
          align-items: flex-start;
          color: $content-color;

          .feather {
            @include pseudowh($width: 17px, $height: auto);
            margin-top: 3px;
          }

          h6 {
            font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
            width: calc(100% - 17px);
          }
        }
      }
    }
  }

  .delivery-box {
    @include flex_wrap($dis: flex, $wrap: wrap, $gap: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))));
    margin-top: 20px;
    align-items: center;

    li {
      .delivery-box {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 12px);
        align-items: center;
        margin-top: 0;

        .delivery-icon {
          img {
            @include pseudowh($width: 30px, $height: auto);
          }
        }
      }
    }
  }
}

.client-section {
  background-color: $light-gray;

  .clint-contain {
    background: $white;
    padding: calc(27px + (50 - 27) * ((100vw - 320px) / (1920 - 320)));
    border-radius: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
    position: relative;

    &:hover {
      .client-icon {
        img {
          animation-name: bounce;
          animation-duration: 1s;
          animation-fill-mode: both;
        }
      }

      h2 {
        opacity: 0.09;
        margin-right: 9px;
      }

      h4 {
        color: var(--theme-color);
      }
    }

    .client-icon {
      @include pseudowh($width: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320))),
        $height: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320))));
      margin-bottom: 14px;
      margin-top: -11px;

      img {
        @include pseudowh($width: 74%, $height: 100%);
        object-fit: contain;
      }
    }

    h2 {
      position: absolute;
      top: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
      right: calc(24px + (34 - 24) * ((100vw - 320px) / (1920 - 320)));
      color: $content-color;
      opacity: 0.05;
      font-size: calc(52px + (70 - 52) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 800;
      transition: all 0.3s ease-in-out;
    }

    h4 {
      font-size: calc(22px + (24 - 22) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: 7px;
      font-weight: 600;
      position: relative;
      transition: all 0.3s ease-in-out;
    }

    p {
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      color: $content-color;
      margin: 0 auto;
      line-height: 1.7;
      margin-bottom: -5px;
      transition: all 0.3s ease-in-out;
    }
  }

  .client-box {
    .client-list {
      @include flex_common($dis: flex, $align: center, $justify: space-between);

      li {
        width: 460px;
        position: relative;
      }
    }
  }
}

.team-section {
  .team-box {
    &:hover {
      .team-iamge {
        img {
          transform: scale(1.1);
          filter: grayscale(0);
        }

        .team-media {
          opacity: 1;
          right: 10px;
        }
      }
    }

    .team-iamge {
      @include pseudowh($width: 50%, $height: auto);
      border-radius: 100%;
      position: relative;
      overflow: hidden;
      margin: 0 auto;

      img {
        transition: all 0.3s ease-in-out;
        filter: grayscale(1);
      }
    }

    .team-name {
      text-align: center;
      margin-top: 20px;

      h3 {
        font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
        letter-spacing: 0.7px;
        font-weight: 700;
      }

      h5 {
        margin-top: 6px;
        letter-spacing: 0.5px;
        color: $content-color;
      }

      p {
        font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
        line-height: 1.6;
        color: $content-color;
        margin: 9px auto 14px;
        width: 80%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;

        @include mq-max(2xs) {
          width: 100%;
        }
      }

      .team-media {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 14px);
        position: relative;
        margin-top: 15px;
        justify-content: center;
        transition: all 0.3s ease-in-out;

        li {
          a {
            @include flex_common;
            @include pseudowh($width: 30px, $height: 30px);
            font-size: 16px;
            color: $white;
            border-radius: 5px;
            background-color: #ededed;

            &.fb-bg {
              color: #4267b2;
              transition: all 0.3s ease-in-out;

              &:hover {
                background-color: #4267b2;
                color: $white;
              }
            }

            &.twitter-bg {
              color: #00acee;
              transition: all 0.3s ease-in-out;

              &:hover {
                background-color: #00acee;
                color: $white;
              }
            }

            &.pint-bg {
              color: #c8232c;
              transition: all 0.3s ease-in-out;

              &:hover {
                background-color: #c8232c;
                color: $white;
              }
            }

            &.insta-bg {
              color: #f56040;
              transition: all 0.3s ease-in-out;

              &:hover {
                background-color: #f56040;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

.review-section {
  background-color: $light-gray;

  .reviewer-box {
    background-color: $white;
    padding: calc(18px + (34 - 18) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 10px;
    z-index: 0;
    position: relative;
    overflow: hidden;

    &:hover {
      i {
        color: var(--theme-color);
        font-size: calc(115px + (168 - 115) * ((100vw - 320px) / (1920 - 320)));
        opacity: 0.15;
        transform: rotate(7deg);
        bottom: calc(-20px + (-26 - -20) * ((100vw - 320px) / (1920 - 320)));
        right: calc(-13px + (-11 - -13) * ((100vw - 320px) / (1920 - 320)));
      }
    }

    i {
      position: absolute;
      font-size: calc(115px + (147 - 115) * ((100vw - 320px) / (1920 - 320)));
      opacity: 0.05;
      z-index: -1;
      transform: rotate(9deg);
      bottom: calc(-27px + (-36 - -27) * ((100vw - 320px) / (1920 - 320)));
      right: calc(-20px + (-23 - -20) * ((100vw - 320px) / (1920 - 320)));
      transition: all 0.3s ease-in-out;
      line-height: 1;

      body.rtl & {
        transform: rotate(-15deg) rotateY(183deg);
        left: -8px;
        right: unset;
      }
    }

    h3 {
      font-weight: 400;
      margin: 10px 0 13px;
      font-size: 20px;
      line-height: 1.5;
    }

    p {
      color: $content-color;
      line-height: 1.7;
      margin-bottom: 23px;
      font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    }

    .reviewer-profile {
      @include flex_wrap($dis: flex, $wrap: wrap, $gap: calc(12px + (19 - 12) * ((100vw - 320px) / (1920 - 320))));
      align-items: center;

      .reviewer-image {
        @include pseudowh($width: calc(65px + (75 - 65) * ((100vw - 320px) / (1920 - 320))), $height: auto);
        border-radius: 8px;
        overflow: hidden;

        img {
          @include pseudowh;
          object-fit: contain;
        }
      }

      .reviewer-name {
        h4 {
          font-weight: 700;
          font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
          color: var(--theme-color);
        }

        h6 {
          color: $content-color;
          margin-top: 6px;
        }
      }
    }
  }
}

/**=====================
     Order Tracking Scss
==========================**/
.order-detail {
  .order-image {
    background-color: $light-gray;
    padding: 40px;
    border-radius: 8px;
    text-align: center;

    img {
      width: 360px;
      margin: 0 auto;
    }
  }

  .order-details-contain {
    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320))));
    background-color: $light-gray;
    padding: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 8px;
    height: 100%;

    .order-tracking-icon {
      .feather {
        @include pseudowh($width: 25px, $height: auto);
        stroke-width: 1.4px;
      }
    }

    .order-details-name {
      h2 {
        font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
      }

      h4 {
        line-height: 1.4;
        font-weight: 500;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
      }

      h5 {
        margin-bottom: 7px;
      }

      img {
        margin-top: calc(6px + (12 - 6) * ((100vw - 320px) / (1920 - 320)));
        width: calc(160px + (200 - 160) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }

  .progtrckr {
    margin: 15px 0 0;
    padding: 0;
    display: flex;
    align-items: flex-start;

    @include mq-max(sm) {
      display: inline;
      margin: 30px 0;
      overflow: hidden;
    }

    li {
      display: inline-block;
      text-align: center;
      margin: 10px 0;
      position: relative;

      @include mq-max(sm) {
        margin: 0;
        width: 50% !important;
      }

      h5 {
        margin-top: 20px;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        font-weight: 500;

        @include mq-max(sm) {
          display: block;
          text-align: left;
          margin-top: 0;

          body.rtl & {
            text-align: right;
          }
        }
      }

      &:before {
        position: relative;
        top: -2px;
        float: left;
        left: 50% !important;
        line-height: 1;
        transform: translate(-50%, -50%) !important;

        @include mq-max(sm) {
          position: relative;
          top: 10px;
          float: left;
          left: -2px !important;
          line-height: 1;
          transform: translate(-50%, -50%) !important;

          body.rtl & {
            float: right;
            right: -32px !important;
            left: unset !important;
          }
        }
      }

      &.progtrckr-todo {
        color: $content-color;
        border-top: 4px solid #959595;
        width: 100% !important;

        @include mq-max(sm) {
          position: relative;
          text-align: left;
          margin-left: 30px;
          align-items: flex-start;
          padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
          padding-left: 30px;
          border-top: unset;
          border-left: 4px solid #959595;

          body.rtl & {
            border-left: none;
            border-right: 4px solid $content-color;
            margin-left: 0;
            margin-right: 30px;
            align-items: flex-end;
            padding-left: 0;
            padding-right: 30px;
            text-align: right;
          }
        }

        &:before {
          @include font;
          @include flex_common;
          @include pseudowh($width: 30px, $height: 30px);
          padding-top: 2px;
          content: "";
          color: $white;
          background: #959595;
          line-height: 35px;
          border: none;
          border-radius: 35px;
          font-size: 16px;

          @include mq-max(sm) {
            position: absolute;
            top: 10px;
            left: -17px;
            line-height: 1;
            transform: translateY(-50%);

            body.rtl & {
              left: unset;
              right: -17px;
            }
          }
        }

        h6 {
          font-size: 13px;
          margin-top: 8px;
        }
      }

      &.progtrckr-done {
        color: $title-color;
        border-top: 4px solid var(--theme-color);
        width: 100% !important;

        &:first-of-type {
          @include mq-max(sm) {
            padding-top: 5px;
          }
        }

        @include mq-max(sm) {
          position: relative;
          text-align: left;
          margin-left: 30px;
          align-items: self-start;
          padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
          border-top: unset;
          border-left: 4px solid var(--theme-color);

          body.rtl & {
            margin-left: 0;
            margin-right: 30px;
            text-align: right;
            border-left: none;
            border-right: 4px solid var(--theme-color);
            align-items: flex-end;
          }
        }

        &:before {
          @include font;
          @include flex_common;
          @include pseudowh($width: 30px, $height: 30px);
          padding-top: 2px;
          content: "\f00c";
          color: $white;
          background-color: var(--theme-color);
          line-height: 35px;
          border: none;
          border-radius: 35px;
          font-size: 16px;
        }

        h6 {
          font-size: 13px;
          margin-top: 8px;
          color: $content-color;
        }
      }
    }
  }
}

// Order Table Scss
.order-tab-table {
  margin-bottom: 0;

  thead {
    tr {
      th {
        text-align: center;
        background-color: #e7e7e7;
        color: $title-color;
        padding: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320))) calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 400;
      }
    }
  }

  tbody {
    border-top: none !important;

    tr {
      td {
        font-weight: 300;
        text-align: center;
        padding: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320))) calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
        min-width: 160px;
        color: #777;
      }
    }
  }
}

.order-table-section {
  .order-navpills {
    flex-wrap: nowrap;
    gap: calc(6px + (15 - 6) * ((100vw - 320px) / (1920 - 320)));
    overflow: auto hidden;

    .nav-item {
      .nav-link {
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        padding: calc(11px + (14 - 11) * ((100vw - 320px) / (1920 - 320))) calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)));
        color: $content-color;
        font-weight: 600;
        white-space: nowrap;

        &.active {
          background: var(--theme-color);
          color: $white;
        }
      }
    }
  }

  .order-tab {
    margin-top: 22px;

    .order-tab-table {
      margin-bottom: 0;

      thead {
        tr {
          th {
            text-align: center;
            background-color: #e7e7e7;
            color: $title-color;
            padding: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320))) calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
            font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 400;
          }
        }
      }

      tbody {
        border-top: none;

        tr {
          td {
            font-weight: 300;
            text-align: center;
            padding: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320))) calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
            font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
            min-width: 160px;
            color: #777;
          }
        }
      }
    }
  }
}

/**=====================
     Order Scss
==========================**/
.order-table {
  table {
    tbody {
      tr {
        td {
          vertical-align: middle;
        }
      }
    }
  }
}

.order-success-2 {
  tbody {
    border-top: none;
  }
}

/**=====================
     Search Scss
==========================**/
.search-section {
  .search-box {
    position: relative;

    .input-group {
      .form-control {
        padding: calc(5px + (14 - 5) * ((100vw - 320px) / (1920 - 320))) calc(12px + (15 - 12) * ((100vw - 320px) / (1920 - 320)));
        &:focus {
          z-index: 0;
        }
      }

      button {
        z-index: 0;
        border-radius: 0 6px 6px 0;
      }
    }
  }
}

/**=====================
     Vendar detail Scss
==========================**/
.vendor-bottom {
  padding-bottom: 18px;
  border-bottom: 1px solid $border-color;
  margin-bottom: 16px;
}

.vendor-detail-box {
  padding: calc(20px + (28 - 20) * ((100vw - 992px) / (1920 - 992)));
  margin-bottom: 29px;
  border-radius: 9px;
  background: $light-gray;

  @include mq-max(lg) {
    padding: 0;
    box-shadow: none;
    margin-right: 0;
  }

  .vendor-name {
    .vendor-logo {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320)));

      img {
        width: calc(80px + (89 - 80) * ((100vw - 320px) / (1920 - 320)));
        height: auto;
      }

      h3 {
        font-weight: 600;
        text-transform: uppercase;
      }

      .vendor-rating {
        margin-top: 4px;

        .rating {
          li {
            .feather {
              width: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
              height: auto;
            }
          }
        }
      }
    }

    p {
      margin-bottom: -6px;
      line-height: 1.5;
      font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
      color: $content-color;
      margin-top: 15px;
    }
  }

  .vendor-tag {
    h4 {
      text-transform: none;
      font-weight: 500;
      margin-bottom: 17px;
    }

    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: calc(7px + (12 - 7) * ((100vw - 320px) / (1920 - 320)));

      body.rtl & {
        padding: 0;
      }

      li {
        background-color: $border-color;
        letter-spacing: 0.5px;
        padding: 4px 11px;
        border-radius: 3px;
        color: $title-color;
        font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }

  .vendor-share {
    display: flex;
    align-items: center;

    h5 {
      font-weight: 600;
    }

    ul {
      margin-left: 15px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;

      body.rtl & {
        padding: 0;
        margin-left: unset;
        margin-right: 15px;
      }

      li {
        a {
          color: $content-color;
          display: block;
          font-size: 16px;
          line-height: 1;
        }
      }
    }
  }
}

.vendor-detail-box-2 {
  padding: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
  margin-bottom: 30px;
  border-radius: 9px;
  box-shadow: 0 0 8px rgba($title-color, 0.13);
  background: var(--theme-color);
  color: $white;

  .vendor-logo {
    height: 100%;
    @include flex_common;
    text-align: center;

    img {
      width: 85%;
      height: auto;
      filter: invert(1) brightness(100);

      @include mq-max(md) {
        width: 26%;
      }

      @include mq-max(xs) {
        width: 35%;
      }
    }
  }

  .vendor-name {
    height: 100%;

    .vendor-list-name {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: calc(6px + (12 - 6) * ((100vw - 320px) / (1920 - 320)));

      @include mq-max(md) {
        justify-content: center;
      }
    }

    h3 {
      font-weight: 600;
      text-transform: uppercase;
      display: block;
      color: $white;
    }

    .vendor-rating {
      .rating {
        li {
          .feather {
            width: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
            height: auto;
          }
        }
      }
    }

    p {
      margin-bottom: -6px;
      line-height: 1.5;
      font-size: 14px;
      color: #f1f1f1;
      margin-top: 11px;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      @include mq-max(md) {
        text-align: center;
      }
    }
  }

  .vendor-tag {
    h4 {
      text-transform: none;
      font-weight: 500;
      margin-bottom: 17px;
    }

    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: calc(7px + (12 - 7) * ((100vw - 320px) / (1920 - 320)));

      li {
        background-color: $border-color;
        letter-spacing: 0.5px;
        padding: 4px 11px;
        border-radius: 3px;
        color: $title-color;
        font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }

  .share-contact {
    @include flex_common;
    height: 100%;
    text-align: center;

    @include mq-max(md) {
      display: block;
    }

    >div {
      @include mq-max(md) {
        @include flex_common;
        flex-wrap: wrap;
        gap: 16px;
      }
    }

    .vendor-share {
      h5 {
        display: block;
        font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;
      }

      ul {
        @include flex_common;
        margin-top: 8px;
        flex-wrap: wrap;
        gap: 10px;

        body.rtl & {
          padding: 0;
        }

        li {
          position: relative;

          a {
            color: #f1f1f1;
            font-size: 16px;
            width: 20px;
            height: 20px;
            @include flex_common;
          }
        }
      }
    }

    .vendor-contect {
      text-align: center;
      margin-top: 17px;

      @include mq-max(md) {
        margin-top: 0;
      }

      h5 {
        text-align: center;
        font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;
        margin-bottom: 10px;
      }

      button {
        margin: 8px auto 0;
      }
    }
  }
}

/**=====================
     Seller become Scss
==========================**/
.saller-poster-section {
  .poster-box {
    .poster-image {
      margin-bottom: calc(22px + (52 - 22) * ((100vw - 320px) / (1920 - 320)));
      text-align: center;
      height: calc(140px + (500 - 140) * ((100vw - 320px) / (1920 - 320)));
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .saller-button {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 15px;
  }
}

.become-service {
  .service-box {
    text-align: center;
    // background: $light-gray;
    // padding: calc(17px + (45 - 17) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 8px;
    // box-shadow: 0 0 8px rgba($title-color, 0.05);
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: translateY(-10px);

      .service-svg {
        svg {
          transform: scale(1.05);
          fill: var(--theme-color);
        }
      }

      .service-detail {
        h4 {
          color: var(--theme-color);

          &::after {
            width: 68%;
          }
        }
      }
    }

    .service-svg {
      @include pseudowh($width: calc(58px + (80 - 58) * ((100vw - 320px) / (1920 - 320))),
        $height: calc(58px + (80 - 58) * ((100vw - 320px) / (1920 - 320))));
      @include flex_common;
      background: $white;
      margin: 0 auto calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
      border-radius: 5px;
      padding: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320)));

      img {
        @include pseudowh;
        object-fit: contain;
        transition: all 0.3s ease-in-out;

        [class="dark"] & {
          filter: invert(1) brightness(100);
        }
      }
    }

    .service-detail {
      h4 {
        font-weight: 600;
        display: inline-block;
        font-size: 20px;
        margin-bottom: 10px;
        text-transform: capitalize;
        position: relative;
        z-index: 0;
        transition: all 0.3s ease-in-out;

        &::after {
          @include pos;
          bottom: 2px;
          @include center(horizontal);
          width: 0;
          height: 4px;
          background-color: var(--theme-color);
          opacity: 0.3;
          z-index: -1;
          border-radius: 50px;
          transition: all 0.3s ease-in-out;
        }
      }

      p {
        margin: 0;
        line-height: 1.5;
        color: $content-color;
        transition: all 0.3s ease-in-out;
        font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }
}

.business-section {
  background-color: $light-gray;

  .business-contain {
    position: relative;
    z-index: 0;

    &::after {
      @include pos;
      background-image: url("../../images/arrow.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      height: 100%;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      z-index: -1;

      @include mq-max(xl) {
        content: none;
      }
    }

    >.row {
      margin-inline: -50px;

      @include mq-max(xl) {
        margin-inline: 0;
      }

      >div {
        padding-inline: calc(15px + (50 - 15) * ((100vw - 1200px) / (1920 - 1200)));

        @include mq-max(xl) {
          padding-inline: calc(var(--bs-gutter-x) * 0.5);
        }

        &:last-child,
        &:first-child {
          margin-bottom: 40px;

          @include mq-max(xl) {
            margin-bottom: inherit;
          }
        }

        &:nth-child(2) {
          margin-top: 40px;

          @include mq-max(xl) {
            margin-top: var(--bs-gutter-y);
          }
        }
      }
    }

    .business-box {
      text-align: center;
      background-color: $white;
      border-radius: 9px;
      padding: calc(17px + (35 - 17) * ((100vw - 320px) / (1920 - 320)));
      box-shadow: 0 0 8px rgba($title-color, 0.05);
      display: flex;
      align-items: center;

      .business-number {
        width: calc(54px + (65 - 54) * ((100vw - 320px) / (1920 - 320)));
        height: calc(54px + (65 - 54) * ((100vw - 320px) / (1920 - 320)));
        background: var(--theme-color);
        @include flex_common;
        margin: 0 auto calc(19px + (28 - 19) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 5px;

        h2 {
          color: $white;
          font-size: calc(26px + (35 - 26) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 500;
        }
      }

      .business-detail {
        h4 {
          margin-bottom: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 600;
          line-height: 1.4;
        }

        p {
          margin: 0;
          color: $content-color;
          line-height: 1.6;
          font-size: 16px;
        }
      }
    }
  }
}

/**=====================
     Seller Grid Scss
==========================**/
.seller-grid-box {
  background-color: $light-gray;
  padding: calc(12px + (25 - 12) * ((100vw - 320px) / (1920 - 320)));
  border-radius: 14px;
  position: relative;
  box-shadow: 0 0 10px rgba($title-color, 0.05);
  display: block;

  .grid-contain {
    .seller-contact-details {
      @include flex_wrap($dis: flex, $wrap: wrap, $gap: 7px);
      margin-bottom: 25px;

      .saller-contact {
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 5px);
        align-items: flex-start;
        width: 100%;

        .seller-icon {
          i {
            @include flex_common;
            @include pseudowh($width: 25px, $height: 25px);
            font-size: 16px;
            color: var(--theme-color);
          }
        }

        .contact-detail {
          h5 {
            line-height: 1.6;
            color: $title-color;

            span {
              color: $content-color;
            }
          }
        }
      }
    }

    .contain-name {
      @include flex_common($dis: flex, $align: flex-end, $justify: space-between);

      h6 {
        color: $content-color;
        margin-left: 4px;

        body.rtl & {
          margin-left: unset;
          margin-right: 4px;
        }
      }

      h3 {
        font-size: calc(19px + (23 - 19) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;
        margin-bottom: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320)));
        color: $title-color;
      }

      .product-rating {
        margin-bottom: calc(14px + (19 - 14) * ((100vw - 320px) / (1920 - 320)));
      }

      .product-label {
        padding: 5px 10px;
        font-size: 13px;
        letter-spacing: 0.5px;
        border-radius: 4px;
        color: var(--theme-color);
        position: relative;
        z-index: 0;

        &:after {
          @include pos;
          @include pseudowh;
          background-color: var(--theme-color);
          opacity: 0.1;
          left: 0;
          top: 0;
          z-index: -1;
        }
      }

      .grid-image {
        @include pseudowh($width: calc(67px + (100 - 67) * ((100vw - 320px) / (1920 - 320))),
          $height: calc(67px + (100 - 67) * ((100vw - 320px) / (1920 - 320))));
        @include flex_common;
        background: $white;
        padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
        border-radius: calc(7px + (11 - 7) * ((100vw - 320px) / (1920 - 320)));
        box-shadow: 0 0 8px rgba($title-color, 0.07);

        h1 {
          color: $content-color;
          line-height: 1;
          font-size: calc(43px + (70 - 43) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }
  }
}

.seller-grid-box-1 {
  .grid-image {
    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 18px);
    align-items: center;
    margin-bottom: 20px;

    .image {
      @include pseudowh($width: 66px, $height: 66px);
      border-radius: 11px;
    }

    .contain-name {
      width: calc(100% - 66px - 18px);
      position: relative;

      .since-number {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        background-color: $light-gray;
        right: 0;
        padding-left: 5px;

        body.rtl & {
          right: unset;
          left: 0;
        }

        @include mq-max(xs) {
          display: block;
        }

        h6 {
          color: $content-color;
        }

        .product-rating {
          display: block;
          text-align: right;

          body.rtl & {
            text-align: left;
          }

          @include mq-max(xs) {
            margin-top: 2px;
          }
        }
      }

      h3 {
        font-size: calc(19px + (23 - 19) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;
        margin-bottom: 10px;
        color: $title-color;
        width: 50%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
      }

      .product-label {
        padding: 5px 10px;
        font-size: 13px;
        letter-spacing: 0.5px;
        border-radius: 4px;
        color: var(--theme-color);
        position: relative;
        z-index: 0;

        &:after {
          @include pos($pos: absolute, $content: "");
          background-color: var(--theme-color);
          opacity: 0.1;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
      }
    }
  }

  .grid-contain {
    .seller-category {
      @include flex_common($dis: flex, $align: center, $justify: space-between);

      .product-image {
        display: flex;
        flex-wrap: nowrap;

        li {
          @include pseudowh($width: calc(35px + (40 - 35) * ((100vw - 320px) / (1920 - 320))),
            $height: calc(35px + (40 - 35) * ((100vw - 320px) / (1920 - 320))));
          @include flex_common;
          background: $white;
          border-radius: 100%;
          padding: 4px;
          box-shadow: 0 0 2px rgba($title-color, 0.2);
          color: $content-color;
          transition: all 0.3s ease-in-out;

          &:hover {
            transform: scale(1.1) translateY(-7px);
          }

          &:last-child {
            line-height: 1;
            font-weight: 600;
            font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
          }

          +li {
            margin-left: calc(-19px + (-14 - -19) * ((100vw - 320px) / (1920 - 320)));

            body.rtl & {
              margin-right: calc(-19px + (-14 - -19) * ((100vw - 320px) / (1920 - 320)));
              margin-left: unset;
            }
          }
        }
      }
    }
  }
}

/**=====================
     Seller Dashboard Scss
==========================**/
.product-table {
  margin-bottom: 0;

  thead {
    tr {
      th {
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        white-space: nowrap;
        min-width: 90px;
        color: $title-color;
        border-color: $border-color;
      }
    }
  }

  tbody {
    border-top: 1px solid $border-color !important;

    tr {
      &:last-child {
        td {
          border-bottom: none;
          padding-bottom: 0;
        }
      }

      td {
        text-align: center;
        padding: 12px 15px;
        vertical-align: middle;
        white-space: nowrap;
        max-width: 190px;
        color: $content-color;
        font-size: 15px;

        h6 {
          font-size: 15px;
        }

        &:nth-child(2) {
          max-width: 150px;

          h6 {
            color: $content-color;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            font-size: 15px;
          }
        }

        &.product-image {
          width: 90px;
        }

        &.efit-delete {
          .feather {
            width: 19px;
            stroke-width: 1.6px;

            &.edit {
              color: var(--theme-color);
            }

            &.delete {
              color: #ff7272;
              margin-left: 10px;

              body.rtl & {
                margin-left: unset;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.order-table {
  margin-bottom: 0;

  &-2 {
    tbody {
      border-top: none !important;

      tr {
        td {
          text-align: left;
        }
      }
    }
  }

  thead {
    tr {
      th {
        text-align: center;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;
        white-space: nowrap;
        min-width: 90px;
      }
    }
  }

  tbody {
    border-top: 1px solid $border-color;

    tr {
      &:last-child {
        td {
          border-bottom: none;
          padding-bottom: 0;
        }
      }

      td {
        text-align: center;
        padding: 12px 15px;
        vertical-align: middle;
        white-space: nowrap;

        &:nth-child(2) {
          color: $content-color;
        }

        label {
          padding: 3px 12px;
          font-size: 12px;
          border-radius: 50px;

          &.success {
            color: var(--theme-color);
          }

          &.danger {
            background-color: rgba(255, 114, 114, 0.1);
            color: #ff7272;
          }
        }

        &:first-child {
          font-weight: 600;
        }
      }
    }
  }
}

.profile-tab {
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: calc(15px + (21 - 15) * ((100vw - 320px) / (1920 - 320)));

    li {
      display: flex;
      align-items: center;
      width: 100%;

      @include mq-max(sm) {
        display: block;
      }

      h5 {
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

        &:first-child {
          width: 220px;
        }

        &:last-child {
          color: $content-color;

          @include mq-max(sm) {
            margin-top: 8px;
          }
        }
      }
    }
  }
}

.deactivate-box {
  padding: 15px 22px;
  background: rgba(255, 165, 59, 0.1);
  border: 1px dashed #ffa53b;
  border-radius: 7px;
  display: flex;
  flex-wrap: nowrap;
  gap: 17px;
  margin-bottom: 21px;

  .deactivate-icon {
    width: 30px;
    height: 30px;
    background: rgba(254, 163, 72, 0.3);
    color: #fea348;
    border-radius: 2px;
    @include flex_common;
    font-size: 18px;
  }

  .deactivate-contain {
    h3 {
      font-weight: 600;
      margin-bottom: 5px;
    }

    h5 {
      color: $content-color;
      margin-bottom: 10px;
    }

    a {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.dashboard-privacy {
  .privacy-box {
    .custom-form-check-2 {
      label {
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }
}

.apexcharts-tooltip {
  * {
    display: flex;
  }
}

.apexcharts-toolbar {
  z-index: 0;
}

.product-header-top {
  .wishlist-button {
    width: 26px;
    height: 26px;
    background-color: $white;
    border-radius: 100%;
    padding: 0;
    box-shadow: 0 3px 3px rgba(34, 34, 34, 0.24);
    @include flex_common;
    line-height: 1;
    position: absolute;
    right: 0;
    top: 0;

    body.rtl & {
      right: unset;
      left: 0;
    }

    svg {
      width: 17px;
      height: 17px;
    }
  }
}

// price range
.ngx-slider {
  .ngx-slider-selection {
    background: var(--theme-color) !important;
  }

  .ngx-slider-pointer {
    width: 22px !important;
    height: 22px !important;
    top: -10px !important;
    background-color: var(--theme-color) !important;

    &:after {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
    }
  }

  .ngx-slider-active {
    &:after {
      background-color: #ffffff !important;
    }
  }
}

.no-data-added {
  text-align: center;
  padding: 60px 0;

  img {
    width: 25%;
    margin-bottom: 20px;
  }

  h4 {
    font-weight: 500;
    font-size: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)));
    color: #232323;
    margin-bottom: 0;
  }

  &.collection-no-data {
    padding: 120px 0;

    img {
      width: 30%;
    }

    p {
      width: 44%;
    }
  }

  p {
    width: 100%;
    margin: 10px auto 0;
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.4;
    color: $content-color;
  }

  &.category-no-data {
    img {
      width: 64%;
    }
  }

  &.no-shipping {
    img {
      width: 32%;

      @media (max-width: 991px) {
        width: 42%;
      }

      @media (max-width: 576px) {
        width: 50%;
      }

      @media (max-width: 420px) {
        width: 80%;
      }
    }
  }
}

.post-question-box {
  margin-bottom: 32px;

  h4 {
    color: $title-color;
    font-weight: 500;
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;

    a {
      width: auto;
      display: inline-block;
      font-size: calc(14px + (17 - 14) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 500;
      border: none;
      border-radius: 6px;
      background-color: #f8f8f8;
      color: #222;
      padding: calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320))) calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
  }
}
